import classnames from 'classnames/bind'
import { useFormContext } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'

import Input, { InputProps } from '~/components/Form/Input'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export default function BirthDate({
  className,
  name = 'customer_birthday',
  defaultValue,
  required = true,
}: InputProps) {
  const { watch } = useFormContext() || {}
  const value = watch(name)
  const t = useTranslate()
  const dateOffset = 1000 * 60 * 60 * 24 * 365 * 10

  const minDate = '1900-01-01'
  const maxDate = new Date(Date.now() - dateOffset)
    ?.toISOString()
    ?.split('T')?.[0]

  return (
    <div className={cx(className, css.BirthDate)}>
      <div className={css.inputs}>
        <Input
          className={cx(css.dateBirthInput, { filled: Boolean(value) })}
          name={name}
          defaultValue={defaultValue}
          type="date"
          autoComplete="bday"
          required={required}
          min={minDate}
          max={maxDate}
          placeholder={t('form.birthday')}
        />
      </div>
    </div>
  )
}
