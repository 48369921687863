import { QueryPayload } from '~/lib/handle-api'

import { Metafield } from '@unlikelystudio/ecommerce-typed/types/shopify-admin'

import { stringIsNotEmpty } from '~/utils/check-empty-string'
import { fromKeyValuePairsToMetafields } from '~/utils/metafields'

interface GetQueryPayloadParams {
  locale?: string
  data: {
    [key: string]: string | number | string[]
  }
  metafields?: {
    values?: {
      [key: string]: string
    }
    initials?: Metafield[]
  }
}

export default function getQueryPayload({
  locale,
  data,
  metafields = {},
}: GetQueryPayloadParams): QueryPayload {
  const formattedData = Object.assign(
    {},
    ...Object.entries(data)
      ?.filter(([, value]) => {
        return (
          (value !== null && value !== undefined) ||
          stringIsNotEmpty(value as string)
        )
      })
      .map(([key, value]) => ({ [key]: value })),
  )

  const formattedMetafields = fromKeyValuePairsToMetafields(
    metafields?.values,
    metafields?.initials ?? [],
  )

  return {
    ...(locale ? { locale } : {}),
    ...formattedData,
    ...(formattedMetafields && { metafields: formattedMetafields }),
  }
}
