import classnames from 'classnames/bind'
import React, { useState } from 'react'

import { ErrorComponent } from '~/components/Form/Input'
import Radio, { RadioProps } from '~/components/Form/Radio'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RadioGroupProps extends RadioProps {
  className?: string
  radios?: RadioProps[]
  withBackground?: boolean
  radioClassName?: string
}

function RadioGroup({
  className,
  radios,
  radioClassName,
  children,
  withBackground,
  ...rest
}: RadioGroupProps) {
  const [error, setError] = useState(null)
  return (
    <div className={cx(css.RadioGroup, { withBackground })}>
      <div className={cx(className, css.radios)}>
        {children}
        {radios?.map((radio, index) => (
          <Radio
            {...radio}
            className={cx(radioClassName, radio?.className)}
            withBackground={withBackground}
            key={`${radio.name}${index}`}
            index={index}
            onError={(err) => setError(err)}
            {...rest}
          />
        ))}
      </div>
      <ErrorComponent className={css.errors} error={error} />
    </div>
  )
}

export default RadioGroup
