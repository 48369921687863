import classnames from 'classnames/bind'
import React, { ReactNode } from 'react'

import Input, { InputProps } from '~/components/Form/Input'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RadioProps extends InputProps {
  className?: string
  index?: number
  withBackground?: boolean
  label?: ReactNode | ReactNode[]
}

function Radio({
  className,
  label,
  withBackground,
  ...inputProps
}: RadioProps) {
  const labelTextStyle = useStyle({ textPreset: 'text-12-14' })

  return (
    <label className={cx(className, css.Radio, { withBackground })}>
      <span className={css.container}>
        <Input
          type="radio"
          {...inputProps}
          className={css.inputComponent}
          inputClassName={css.input}
          displayErrors={false}>
          {({ withError }) => (
            <span className={cx(css.checkmark, { withError })} />
          )}
        </Input>
      </span>
      <span className={cx(css.label, labelTextStyle)}>{label}</span>
    </label>
  )
}

Radio.defaultProps = {}

export default Radio
