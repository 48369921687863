import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'

import { InputProps } from '~/components/Form/Input'
import RadioGroup from '~/components/Form/RadioGroup'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface CivilityProps extends InputProps {
  defaultRadio?: string
}

export default function Civility({
  className,
  name = 'customer_gender',
  defaultRadio,
  required = true,
}: CivilityProps) {
  const t = useTranslate()
  const radioStyle = useStyle({ textPreset: 'input-14' })

  return (
    <div className={cx(className, css.Civility, radioStyle)}>
      <RadioGroup
        required={required}
        radios={[
          {
            name,
            label: t('form.civility.female'),
            value: 'female',
            defaultChecked: 'female' === defaultRadio,
          },
          {
            name,
            label: t('form.civility.male'),
            value: 'male',
            defaultChecked: 'male' === defaultRadio,
          },
        ]}></RadioGroup>
    </div>
  )
}
