import React from 'react'
import { useTranslate } from 'react-polyglot'

import Input, { InputProps } from '~/components/Form/Input'

export default function LastName({
  placeholder,
  required = true,
  name = 'lastName',
  autoComplete = 'family-name',
  ...rest
}: InputProps) {
  const t = useTranslate()
  const processedPlaceholder = placeholder || t('form.last_name')

  return (
    <Input
      name={name}
      type="text"
      autoComplete={autoComplete}
      required={required}
      placeholder={processedPlaceholder}
      {...rest}
    />
  )
}
